
import { Dispatch } from "redux";
import api from ".";
import { getTag } from "../Action/tag.action";

export const getAllTags = (id: any) => (dispatch: Dispatch) => {
    console.log("fids", `/tags/${id}`);
    api.get(`/tags/${id}`)
        .then((response) => {
            console.log("abcd", response);
            
            dispatch(getTag(response.data));
        })
        .catch((err) => {
            
            console.log("err", err);
        });
};
