import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../AppButton";

const Index = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);
    return (
        <>
            <div className="empty-cart">
                <div className="emptycart__content">
                    <img src="/Images/cart.png" alt="empty-cart" />
                    <p>Your Cart is Empty</p>
                </div>
                <div className="back-btn" onClick={goBack}>
                    <AppButton btnType="primary">Back To Menu</AppButton>
                </div>
            </div>
        </>
    );
};
export default Index;
