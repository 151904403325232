import { Dispatch } from "redux";
import api from ".";

import { getProfile } from "../Action/resturantProfile.action";
import { displayLoader } from "../Action/loader.action";

export const getProfileApi = (slug: any) => (dispatch: Dispatch) => {
    api.get(`/profile/${slug}`)
        .then((response) => {
            dispatch(displayLoader(false));
            dispatch(getProfile(response.data));
        })
        .catch((err) => {
            dispatch(displayLoader(false));
            console.log("err", err);
        });
};
