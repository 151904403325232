
import { ADD_TO_CART, GET_CART } from "../const";


export const addToCart = (data: any) => {
    return {
        type: ADD_TO_CART,
        payload: data,
    };
};
export const get_Cart = (data: any) => {
    return {
        type: GET_CART,
        payload: data,
    };
};

export const removeFromCart = (data: any) => {
    return {
        type: "REMOVE_FROM_CART",
        payload: data,
    };
};
export const emptyCart=()=>{
    return{
        type:"EMPTY_CART",

    }
}
