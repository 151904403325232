import { ResturantProfileAction, loaderAction } from "../const";
import { ResturantProfileType } from "../Type/ResturantProfile.type";

export interface ResturantProfileReducer {
    profile: ResturantProfileType;
    loader: boolean;

    
}
const inititalState: ResturantProfileReducer = {
    profile: {} as ResturantProfileType,
    loader: true,
};

const resturantProfileReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case ResturantProfileAction.GET_RESPROFILE:
            return {
                ...state,
                profile: payload,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default resturantProfileReducer;
