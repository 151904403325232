import { notification } from "antd";
import { Dispatch } from "redux";
import api from ".";
import {
    addToCart,
    get_Cart,
    removeFromCart,
} from "../Action/cart.action";

import { displayLoader } from "../Action/loader.action";


export const handleAddToCart =
    (data: any, navigate?: any, show?: boolean) => (dispatch: Dispatch) => {
        const key = "updatable";
        const session_id = localStorage.getItem("session_id");

        if (session_id) {
            api.post(`/cart/add`, data, {
                headers: {
                    guestid: localStorage.getItem("session_id") || "",
                },
            })
                .then((response: any) => {
                    dispatch(displayLoader(false));
                    localStorage.setItem(
                        "session_id",
                        response.data.data.session_id
                    );
                    dispatch(addToCart(response.data.data));
                    show &&
                        setTimeout(() => {
                            notification.open({
                                key,
                                message: "Added to cart",
                                duration: 2,
                            });
                        }, 1000);
                    navigate && navigate(-1);
                })
                .catch((err: any) => {
                    dispatch(displayLoader(false));
                    notification.open({
                        key,
                        message: "Error",
                        description: "failed to add in cart",
                    });
                });
        } else {
            api.post(`/cart/add`, data)
                .then((response: any) => {
                    dispatch(displayLoader(false));
                    localStorage.setItem(
                        "session_id",
                        response.data.data.session_id
                    );
                    dispatch(addToCart(response.data.data));
                    show &&
                        notification.open({
                            key,
                            message: "Added to cart",
                            duration: 2,
                        });
                    navigate && navigate(-1);
                })
                .catch((err: any) => {
                    dispatch(displayLoader(false));
                    notification.open({
                        key,
                        message: "Error",
                        description: "failed to add in cart",
                    });
                });
        }
    };
    export const handleUpdateCart =
    (data: any, navigate?: any, show?: boolean) => (dispatch: Dispatch) => {
        const key = "updatable";
        const session_id = localStorage.getItem("session_id");

        if (session_id) {
            api.post(`/cart/update`, data, {
                headers: {
                    guestid: localStorage.getItem("session_id") || "",
                },
            })
                .then((response: any) => {
                    dispatch(displayLoader(false));
                    localStorage.setItem(
                        "session_id",
                        response.data.data.session_id
                    );
                    dispatch(addToCart(response.data.data));
                   
                })
                .catch((err: any) => {
                    dispatch(displayLoader(false));
                });
        } else {
            api.post(`/cart/update`, data)
                .then((response: any) => {
                    dispatch(displayLoader(false));
                    localStorage.setItem(
                        "session_id",
                        response.data.data.session_id
                    );
                    dispatch(addToCart(response.data.data));
                    
                })
                .catch((err: any) => {
                    dispatch(displayLoader(false));

                   
                });
        }
    };
export const getCart = () => (dispatch: Dispatch) => {
    const key = "updatable";
    console.log("local", localStorage.getItem("guestId"));
    api.get(`/cart`, {
        headers: {
            guestid: localStorage.getItem("session_id") || "",
        },
    })
        .then((response: any) => {
           
            dispatch(displayLoader(false));
            dispatch(get_Cart(response.data));

        })
        .catch((err: any) => {
            dispatch(displayLoader(false));
        
        });
};
export const handleDelete = (productId:string,productKey: string) => (dispatch: Dispatch) => {

    api.post(
        `/cart/delete`,
        { productId,productKey },
        {
            headers: {
                guestid: localStorage.getItem("session_id") || "",
            },
        }
    )
        .then((response: any) => {
            dispatch(displayLoader(false));
            dispatch(removeFromCart(response.data.data));
            
        })
        .catch((err: any) => {
            dispatch(displayLoader(false));
        });
};
