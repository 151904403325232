import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllCategories } from "../../store/api/category";
import { StateType } from "../../store/Reducer/index.reducer";

const Pricing = (props: any) => {
    const { currentVenue } = useParams();

    const {
        product: Product,
        total,
        subTotal,
        venueCharge,
        tax,
    } = useSelector((state: StateType) => state.cart);
    // const cart = useSelector((state: StateType) => state.cart);
    const { receipt, price } = props;
    const curr = useSelector(
        (state: StateType) => state.category?.categoryRes?.currency
    );

    // useEffect(() => {
    //     getAllCategories(currentVenue);
    // }, [currentVenue]);

    return (
        <div className="pricing">
            <>
                <div className="price subtotal">
                    <span>Subtotal</span>
                    <span>
                        {curr}&nbsp;
                        {receipt ? price?.subTotal : subTotal}
                    </span>
                </div>
                {/* {tax > 0 ||
                    (price?.tax > 0 && (
                        <div className="price venue-service">
                            <span>Tax({tax}%)</span>
                            <span>
                                {curr}&nbsp;
                                {receipt
                                    ? ((price?.tax / 100) * subTotal).toFixed(2)
                                    : ((tax / 100) * subTotal).toFixed(2)}
                            </span>
                        </div>
                    ))} */}
                    {receipt
                    ? price?.tax> 0 && (
                          <div className="price venue-service">
                              <span>tax({price?.tax}%)</span>
                              <span>
                                  {curr}&nbsp;
                                  {(
                                      (price?.tax / 100) *
                                      price?.subTotal
                                  ).toFixed(2)}
                              </span>
                          </div>
                      )
                    : tax > 0 && (
                          <div className="price venue-service">
                              <span>Tax({tax}%)</span>

                              <span>
                                  {curr}&nbsp;
                                  {((tax / 100) * subTotal).toFixed(2)}
                              </span>
                          </div>
                      )}
                {console.log(
                    "venuechrge",
                    venueCharge,
                    typeof venueCharge,
                    price?.venueCharge
                )}
                {receipt
                    ? price?.venueCharge > 0 && (
                          <div className="price venue-service">
                              <span>Service Charge({price?.venueCharge}%)</span>
                              <span>
                                  {curr}&nbsp;
                                  {(
                                      (price?.venueCharge / 100) *
                                      price?.subTotal
                                  ).toFixed(2)}
                              </span>
                          </div>
                      )
                    : venueCharge > 0 && (
                          <div className="price venue-service">
                              <span>Service Charge({venueCharge}%)</span>

                              <span>
                                  {curr}&nbsp;
                                  {((venueCharge / 100) * subTotal).toFixed(2)}
                              </span>
                          </div>
                      )}
                {/* {venueCharge > 0 || price?.venueCharge > 0 &&
                <div className="price venue-service">
                    <span>Service Charge({venueCharge}%)</span>
                    
                    <span>
                        {curr}&nbsp;
                        {receipt? ((price?.venueCharge / 100) * subTotal).toFixed(2) :
                        ((venueCharge / 100) * subTotal).toFixed(2)}
                    </span>
                </div>} */}
                <div className="total">
                    <span>Total</span>
                    <span>
                        {curr}&nbsp;
                        {receipt ? Math.round(price?.total) : Math.round(total)}
                    </span>
                </div>
            </>
        </div>
    );
};

export default Pricing;
