import { Dispatch } from "redux";
import api from ".";

import { getCategory } from "../Action/category.action";
import { displayLoader } from "../Action/loader.action";

export const getAllCategories = (slug: any) => (dispatch: Dispatch) => {
    api.get(`/category/venue/${slug}`)
        .then((response) => {
            dispatch(displayLoader(false));
            dispatch(
                getCategory({
                    ...response.data?.data[0],
                    currency: getCurrencySymbol(response.data?.currency),
                })
            );
        })
        .catch((err) => {
            dispatch(displayLoader(false));
        });
};
const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case "yuan":
            return "CNY";
        case "ringgit":
            return "MYR";
        case "thai-baht":
            return "THB";
        case "myanmar-kyat":
            return "MMK";
        default:
            return "$";
    }
};
