import categoryReducer, { CategoryReducer } from "./category.reducer";
import { combineReducers } from "redux";
import menuReducer, { MenuReducer } from "./menu.reducer";
import detailProductReducer, {
    DetailProductReducer,
} from "./detailproduct.reducer";
import cartReducer, { CartReducar } from "./cart.reducer";
import tagReducer, { TagReducer } from "./tag.reducer";
import orderReducer, { OrderReducer } from "./order.reducer";
import feedbackReducer, { FeedbackReducer } from "./feedback.reducer";
import resturantProfileReducer,{ResturantProfileReducer} from "./resturantProfile.reducer";

export interface StateType {
    category: CategoryReducer;
    menu: MenuReducer;
    feedback: FeedbackReducer;
    detailProduct: DetailProductReducer;
    cart: CartReducar;
    profile: ResturantProfileReducer;
    tags: TagReducer;
    order: OrderReducer;
}
const rootReducer = combineReducers({
    category: categoryReducer,
    menu: menuReducer,
    detailProduct: detailProductReducer,
    feedback: feedbackReducer,
    cart: cartReducer,
    tags: tagReducer,
    profile: resturantProfileReducer,
    order: orderReducer,
});

export default rootReducer;
