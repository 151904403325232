import React, { useEffect, useRef, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { ApiCreateOrder } from '../../store/api/order';
import { CartReducar } from '../../store/Reducer/cart.reducer';
import { StateType } from '../../store/Reducer/index.reducer';
import {IoMdClose} from "react-icons/io"
import Pricing from '../Pricing';
import { FiPlus } from 'react-icons/fi';
import { HiPlusSm } from 'react-icons/hi';
import { HomeIMAGE_BASE_URL } from '../../constant';

// import ReactToPdf from '../ReactToPdf/reactToPdf';


// import ReactToPdf from '../ReactToPdf/ReactToPdf';

const Receipt = ()=> {
  const[print,setPrint]=useState(false)
  const ProductL = useSelector((state: any) => state?.order?.order?.order);
  const curr = useSelector(
    (state: StateType) => state.category?.categoryRes?.currency
);
  console.log("prod",ProductL)
  const product=JSON.parse(ProductL.order)
  console.log("prod",product)

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
};
const dispatch: any = useDispatch();
    const { currentVenue } = useParams();

const price={
  product: product,
  total:ProductL.total,
  subTotal:ProductL.subtotal,
  tax:product.tax,
  venueCharge:ProductL.venue_charge
}


let ref= useRef(null);
const homeData = useSelector((state: StateType) => state.profile?.profile);

const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  // let shortMonth = date.toLocaleString('en-us', { month: 'short' }); 

  const cdate = new Date().getDate();
  const today = Date.now();
const date =`${new Intl.DateTimeFormat('en-US', {month: 'short',day: '2-digit',year: 'numeric'}).format(today)}`;
  const ProductList=[{
    product:"Misson Ramen Bowl",
    productPrice:"MMK 30",
    toppingName:"Extra Egg",
    toppingPrice:"MMK 30",
    Pqty:"1x",
    tqty:"2x"
    
  },
  {
    product:"side Salad",
    productPrice:"MMK 30",
    Pqty:"1x",

  },
  {
    product:"Misson Ramen Bowl",
    tqty:"1x",
    Pqty:"1x",
    productPrice:"MMK 30",

    toppingName:"Extra Egg",
    
    toppingPrice:"MMK 30"

  },
  {
    product:"Misson Ramen Bowl",
    productPrice:"MMK 30",
    Pqty:"1x",

    toppingName:"Extra Egg",
    toppingPrice:"MMK 30"
  }
]
  return (
    <div className='receipt_wrapper'>
      <div className="detailmenu__arrow" onClick={goBack}>
                    <BsArrowLeftShort className="arrow-left" />
                    </div>
    <div ref={ref}>
      <div className='receipt-logo'>
        {/* <img src="/Images/receiptlogo.svg"/> */}
        <img src={`${HomeIMAGE_BASE_URL}/${homeData?.profile?.banner}`} alt="banner"/>
        <div>{date}</div>
      </div>
      <div className='receipt-logoafterdownload'>
        {/* <img src="/Images/receiptlogo.svg"/> */}
        
        <img src={`${HomeIMAGE_BASE_URL}/${homeData?.profile?.logo}`} alt="banner"/>
        <div>{date}</div>
      </div>
    <div className='order-receipt'>
      <h2>Order Receipt</h2>
      <div className='receipt_productcontainer'>         
     {product?.cart?.map((data:any,index:number)=>{
      return(
         <div className='receipt-product' key={index}>
          <div>
          <h4>{data?.name}<sub><HiPlusSm className='hiplus_icon'/>{data?.quantity}</sub></h4>
            {/* <div className='receipt_productquantity'>{data?.quantity}</div> */}
            <div className='receipt_productprice'> {curr}&nbsp;{data?.unitPrice}</div>
          </div>
          <div className='receipt_toppingsection'>
            {data?.topping?.map((toppingproduct:any,i:number)=>{
              return(
                <> 
            <div key={i}>
            <h5>{toppingproduct?.name}</h5>
            <div className='receipt_productquantity'>{toppingproduct?.quantity}</div>
            <div className='receipt_productqty'>{curr}&nbsp;{toppingproduct?.price}</div> 
            </div>
            </>
              )
            })}
            </div>
          </div>
           )
          })}
      </div>
      <Pricing price={price} receipt={true}/>
    </div>
    </div>
      <ReactToPrint
      documentTitle="ORDR"
          trigger={() =>   <div className='download_receipt'>
          <img src='/Images/download-icon.svg' alt='download-icon'/>
          <span>Download Receipt</span>
                </div>}
                pageStyle="padding:180px"
              
                 content={() =>ref.current}
        />
    
    </div>
  )
}

export default Receipt;