import { Link } from "react-router-dom";
import AppButton from "../AppButton";

const Verify = () => {
    return (
        <div className="signup_wrapper">
            <div className="signup_container">
                <h1 className="signup_heading">Your account has been verfied</h1>
                <Link to="/about">
                <AppButton btnType="lgbutton">Sign In To Continue</AppButton>
                </Link>
            </div>
        </div>
    );
};

export default Verify;
