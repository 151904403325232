import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
// import Drawer from "../components/Drawer";\
import Drawer from "../../components/Drawer";

import { useDispatch, useSelector } from "react-redux";
import { HomeIMAGE_BASE_URL } from "../../constant";
import { StateType } from "../../store/Reducer/index.reducer";
import { getProfileApi } from "../../store/api/resturantProfile";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AppButton from "../AppButton";
import Loader from "../Loader";

const Index = (props: any) => {
    const dispatch = useDispatch<any>();
    let navigate = useNavigate();
    const { venue } = useParams();
    const [visible, setVisible] = useState(false);
    const [inputTableNum, setInputTableNum] = useState(0);
    const [inputName,setInputName]=useState("")
    const [tablenum, setTableNum] = useState(0);
    const [validationMsgTwo,setValidationMsgTwo] = useState("");
    const [submitType, setSubmitType] = useState<"change" | "submit">("change");
    const homeData = useSelector((state: StateType) => state.profile.profile);
    const loader = useSelector((state: StateType) => state.profile.loader);

    const onSubmit = () => {
        if (
            homeData?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
            submitType == "submit" &&
                navigate(`/menu/${venue}/category`, { replace: true });
        }
        if(!inputName){
            setValidationMsgTwo("Please provide last name on your booking");
            return;
        }
        {
            setTableNumber(inputTableNum);
            submitType == "submit" &&
                navigate(`/menu/${venue}/category`, { replace: true });
        }
        
    };
    const showDrawer = (type: "change" | "submit") => {
        setSubmitType(type);
        tablenum && type == "submit"
            ? navigate(`/menu/${venue}/category`, { replace: true })
            : setVisible(true);
    };
    const onChangeTwo=(e:any)=>{
        {if(!e.target.value){
            setValidationMsgTwo("Please provide last name on your booking");
            
        }
        else{
            setValidationMsgTwo("")
        }
        setInputName(e.target.value)
    }}
    
    useEffect(() => {
        dispatch(getProfileApi(venue));
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, [venue]);
    let table = localStorage.getItem("tablenum");

    // localStorage.setItem("inputName",inputName)
    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
    }, [table]);
    const setTableNumber = (tableNum: number) => {
        setInputTableNum(tableNum);
        localStorage.setItem("tablenum", JSON.stringify(tableNum));
        localStorage.setItem("inputName",inputName)
        console.log("iN,inputName")
    };
    return (
        <>
            <Navbar showNavMenu={true} venue={venue} />
            {loader ? (
                <Loader />
            ) : (
                <div className="welcome-page container">
                    <div className="cover__image">
                        <img
                            src={
                                homeData?.profile?.banner
                                    ? `${HomeIMAGE_BASE_URL}/${homeData?.profile?.banner}`
                                    : "../Images/homecover.png"
                            }
                        />
                    </div>
                    <div className="logo-wrapper">
                        <img
                            src={
                                homeData?.profile?.logo
                                    ? `${HomeIMAGE_BASE_URL}/${homeData?.profile?.logo}`
                                    : "../Images/logoIII.png"
                            }
                            alt="logo"
                        />
                    </div>
                    <div className="home__content">
                        <div className="button_container">
                            <div className="content-box ">
                                <div className="top-content ">
                                    <div className="table-num ">
                                        {tablenum
                                            ? `${
                                                  homeData?.profile?.type == 2
                                                      ? "Room"
                                                      : homeData?.profile
                                                            ?.type == 3
                                                      ? "Tee"
                                                      : "Table"
                                              } ${tablenum}`
                                            : `Select ${
                                                  homeData?.profile?.type == 2
                                                      ? "Room"
                                                      : homeData?.profile
                                                            ?.type == 3
                                                      ? "Tee"
                                                      : "Table"
                                              } Number`}
                                    </div>
                                    <div
                                        className="table-btn "
                                        onClick={() => showDrawer("change")}
                                    >
                                        {tablenum ? "Change" : "Select"}
                                    </div>
                                </div>
                                <div className="content-btn">
                                    <AppButton
                                        btnType="primary"
                                        onClick={() => showDrawer("submit")}
                                    >
                                        View Dine-in Menu
                                    </AppButton>

                                    <Link to={`/menu/${venue}/feedback`}>
                                        <AppButton btnType="secondary">
                                            Leave a Feedback
                                        </AppButton>
                                    </Link>
                                </div>
                            </div>

                            <Drawer
                                visible={visible}
                                onClose={() => setVisible(false)}
                                onSubmit={onSubmit}
                                onChangeOne={(e:any) => setInputTableNum(e.target.value)}
                                onChangeTwo={(e:any) => onChangeTwo(e)}
                                validationMsgOne={
                                    !inputTableNum ||
                                    homeData?.profile?.table?.filter(
                                        (data: any) => data.key == inputTableNum
                                    )?.length
                                        ? ""
                                        : `Please enter correct ${
                                              homeData?.profile?.type == 2
                                                  ? "room"
                                                  : homeData?.profile?.type == 3
                                                  ? "Tee"
                                                  : "table"
                                          } number!`
                                }
                                validationMsgTwo={
                                    validationMsgTwo   
                                }
                                buttondisabled={
                                    homeData?.profile?.table?.filter(
                                        (data: any) => data.key == inputTableNum
                                    )?.length || inputName.length
                                        ? false
                                        : true
                                }
                            />

                            <div className="footer_wrapper">
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            )}
          
        </>
    );
};

export default Index;
