export const ResturantProfileAction = {
    GET_RESPROFILE: "GET_RESPROFILE",
};
export const categoryAction = {
    GET_CATEGORY: "GET_ALL_CATEGORY",
};

export const menuAction = {
    GET_MENU: "GET_ALL_MENU",
};
export const loaderAction = {
    GET_LOADER: "Get_LOADER",
};
export const detailProductAction = {
    GET_DETAILPRODUCT: "GET_DETAILPRODUCT",
};

//cart
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART = "GET_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";

export const tagAction = {
    GET_TAG: "GET_TAG",
};
export const orderAction = {
    CREATE_ORDER: "CREATE_ORDER",
    GET_ORDER:"GET_ORDER"
};
export const CURRENCY = "$";
export const feedbackAction = {
    FEEDBACK_ACTION: "FEEDBACK_ACTION",
};
