import React from "react";
import { Collapse } from "antd";
const menuData = [
    {
        icon: "Images/menuIconII.svg",
        menu: "Venues",
        text: "Delight your guests and increase spending by your regulars up to 30%",
    },
    {
        icon: "Images/menuIconIII.svg",
        menu: "Food Truck",
        text:"Delight your guests and increase spending by your regulars up to 30%"
    },
    {
        icon: "Images/menuIconIV.svg",
        menu: "Events",
        text:"Delight your guests and increase spending by your regulars up to 30%"
    },
    {
        icon: "Images/menuIconI.svg",
        menu: "Room Service",
        text:"Delight your guests and increase spending by your regulars up to 30%"
    },
];
const LandingMenu = () => {
    const { Panel } = Collapse;
    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    return (
        <div className="landingMenu_wrapper">
            <img
                src="Images/lfoodI.png"
                className="imageicon imageiconI"
                alt="bread-img"
            />
            <img
                src="Images/lfoodII.png"
                className="imageicon imageiconII"
                alt="bread-img"
            />
            <h2 className="landing_headingII">A Menu For Everybody</h2>
            <div className="landingmenu_container">
                <div className="landingmenu_left">
                    <img src="Images/lcardII.jpg" alt="image" />
                </div>
                <div className="landingmenu_right">
                    {menuData?.map((data, index) => {
                        return (
                            // <div className="landingmenu_box">
                            //     <div>
                            //         <div className="menu_iconwrapper">
                            //             <img src={data.icon} alt="icon" />
                            //         </div>
                            //         <h3>{data.menu}</h3>
                            //     </div>
                            //     <div>{data.content}</div>
                            // </div>
                            <>
                                <Collapse
                                    accordion
                                    defaultActiveKey={["0"]}
                                    onChange={onChange}
                                >
                                    <Panel
                                        extra={
                                            <div className="menu_iconwrapper">
                                                <img src={data?.icon} />
                                            </div>
                                        }
                                        header={data?.menu}
                                        key={index}
                                        showArrow={false}
                                    >
                                        <p>{data?.text}</p>
                                    </Panel>
                                </Collapse>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LandingMenu;
