
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import Navbar from "../../components/Navbar";

const Verification = () => {

    return (
        <>
            <Navbar showArrow showDineIn />
            <div className="Verification-page container">
                <div className="mobileframe">
                    <img src="../Images/mobileframe.png" alt="image" />
                </div>
                <h1>Verify your number</h1>
                <p>
                    To verify, we’ll send you a 4 digit number via SMS. You’ll
                    only have to do this once.
                </p>
                <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                />
                <div className="submit-btn">
                    <button>Continue</button>
                </div>
                <p className="otp-paragraph">
                    By continuing, you agree with our Terms & Conditions and
                    Privacy Policy.
                </p>
            </div>
        </>
    );
};

export default Verification;
