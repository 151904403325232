import React from "react";
import { BsFillPlayCircleFill, BsFillPlayFill } from "react-icons/bs";
import ReactPlayer from "react-player";

const TrustedSection = () => {
    const imageData = [
        {
            img: "../Images/yahoo.png",
        },
        {
            img: "../Images/nasdaq.png",
        },
        {
            img: "../Images/trustedimgIV.svg",
        },
        {
            img: "../Images/trustedimgI.svg",
            className: "kickstarter",
        },
        {
            img: "../Images/trustedimgIII.svg",
            className: "imagethrift",
        },
    ];
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="trusted_section">
            <div>trusted by more than</div>
            <div>2000+ companies Worldwide</div>
            <div className="trusted_imgsection">
                {imageData?.map((data, index) => {
                    return (
                        <div
                            className={`trusted_imagewrapper ${data.className}`}
                        >
                            <img
                                key={index}
                                src={data.img}
                                alt="trusted-companies-image"
                            />
                        </div>
                    );
                })}
            </div>
            {/* <div className="video-section">
                <img src="../Images/videothumnail.png" alt="image" />
                <div className="playicon_border">
                    <div onClick={handleOpen}>
                        <BsFillPlayCircleFill className="playcircle-icon" />
                    </div>
                </div>
            </div> */}
            <div className="video-section">
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=BNdFuHGjgrU&ab_channel=MrYum"
                    width="100%"
                    playing={true}
                    loop={true}
                    controls={false}
                    playIcon={
                        <div className="playicon_border">
                            <div>
                                <button>
                                    <BsFillPlayFill className="playcircle-icon" />
                                </button>
                            </div>
                        </div>
                    }
                    light="../Images/videothumnail.png"
                />
            </div>
        </div>
    );
};

export default TrustedSection;
