import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import SelectTable from "../../components/SelectTable";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { displayLoader } from "../../store/Action/loader.action";
import { getCart } from "../../store/api/cart";
import Loader from "../../components/Loader";

const Index = () => {
    const { currentVenue } = useParams();
    const dispatch = useDispatch<any>();
    const [visible, setVisible] = useState(false);
    const [tablenum, setTableNum] = useState(0);
    const [validationMsgTwo,setValidationMsgTwo] = useState("")
    const [inputTableNum, setInputTableNum] = useState(0);
    const[inputName,setInputName]=useState("")
const[name,setName]=useState("")
    const cart = useSelector((state: StateType) => state.cart);
    const tableData = useSelector((state: StateType) => state.order);
    const homeData = useSelector((state: StateType) => state.profile.profile);

    const onSubmit = () => {
        if (
            homeData?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
        }
        if(!inputName){
            setValidationMsgTwo("Please provide last name on your booking");
            return;
        }
        {
            setTableNumber(inputTableNum);
        }
       
    };
    const onChangeTwo=(e:any)=>{
        {if(!e.target.value){
            setValidationMsgTwo("Please provide last name on your booking");       
        }
        else{
            setValidationMsgTwo("")
        }
        setInputName(e.target.value)
    }}

    const showDrawer = () => {
        setVisible(true);
    };
    useEffect(() => {
        dispatch(displayLoader(true));
        dispatch(getCart());
    }, [dispatch]);

    
    let table = localStorage.getItem("tablenum");
    let anyName=localStorage.getItem("inputName")

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if(anyName){
            setName(anyName)
        }
    }, [table,anyName]);
    const setTableNumber = (tableNum: number) => {
        setInputTableNum(tableNum);
        localStorage.setItem("tablenum", JSON.stringify(tableNum));
        localStorage.setItem("inputName",inputName)
    };

    return (
        <>
            <Navbar showArrow={true} showDineIn venue={currentVenue} />
            {cart.loader ? (
                <Loader />
            ) : (
                <div className="order-wrapper">
                    {!tablenum ? (
                        <>
                            <h2>Order details</h2>
                            <div className="info-content">
                                <div className="table_info">
                                    Please select a table number
                                </div>
                                <div
                                    className="table_info table_selectoption"
                                    onClick={showDrawer}
                                >
                                    Select
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="choosed-table_section">
                                <span>
                                    {homeData?.profile?.type == 2
                                        ? "Room number"
                                        : homeData?.profile?.type == 3
                                        ? "Tee number"
                                        : "Table number "}
                                </span>
                                <div className="choosedtable__btn">
                                    <button onClick={showDrawer}>
                                        {homeData?.profile?.type == 2
                                            ? "Room "
                                            : homeData?.profile?.type == 3
                                            ? "Tee"
                                            : "Table"}
                                        {tablenum}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    <SelectTable
                        cart={cart}
                        tablenum={tablenum}
                        inputName={name}
                        disabledButton={tablenum ? false : true}
                    />
                </div>
            )}
            <Drawer
                visible={visible}
                onClose={() => setVisible(false)}
                onSubmit={onSubmit}
                onChangeOne={(e) => setInputTableNum(e.target.value)}
                onChangeTwo={(e)=>onChangeTwo(e)}

                validationMsgOne={
                    !inputTableNum ||
                    homeData?.profile?.table?.filter(
                        (data: any) => data.key == inputTableNum
                    )?.length
                        ? ""
                        : `Please enter correct ${
                              homeData?.profile?.type == 2
                                  ? "room"
                                  : homeData?.profile?.type == 3
                                  ? "Tee"
                                  : "table"
                          } number!`
                }
                validationMsgTwo={
                    validationMsgTwo   
                }
                buttondisabled={
                    homeData?.profile?.table?.filter(
                        (data: any) => data?.key == inputTableNum
                    )?.length
                        ? false
                        : true
                }
            />
        </>
    );
};

export default Index;
