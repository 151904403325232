import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { HomeIMAGE_BASE_URL } from "../../constant";
import AppButton from "../AppButton";
const SignUp = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        setPasswordShown(!passwordShown);
    };
    return (
        <div className="signup_wrapper">
            <div className="signup_container">
                <div className="signup_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
                <h2 className="signup_heading">Create a new account</h2>
                <div className="signup_subheading">
                    
                    Or <a href={HomeIMAGE_BASE_URL}>login to your account</a>
                </div>
                <form className="signup_form">
                    <div className="signup_inputfield">
                        <input
                            type="email"
                            placeholder="Enter your email address"
                        />
                    </div>
                    <div className="signup_inputfield">
                        <input
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter your password"
                        />
                    <button
                        onClick={togglePassword}
                        className="signup_togglebutton"
                    >
                        {passwordShown ? (
                            <AiOutlineEye size={20}/>
                        ) : (
                            <AiOutlineEyeInvisible size={20}/>
                        )}
                    </button>
                    </div>
                    <div className="signup_checkcontent">
                        <input type="checkbox" />
                        <span>
                            By proceeding, you agree to the
                            <a href="/terms">
                                Terms and Conditions & Privacy Policy
                            </a>
                        </span>
                    </div>
                    <Link to="/verify">
                        <AppButton btnType="lgbutton">Create</AppButton>
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
