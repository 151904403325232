import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { countryList } from "../../Helpers/countryList";

type PropsType={
    mode?:string;
}
const Country = (props:PropsType) => {
    const {mode } = props;
    return (

        <div>
            <select name="cars" id="cars" placeholder="Select " className="select_input" >
             <option value="" disabled selected hidden>{mode == "country_name"?"- Select your country -" : "+95"}</option>
            {mode == "country_num" && <option value="" disabled selected hidden>+95</option>}

                {countryList?.map((data: any, i: number) => {
                    return (
                        <>
                            <option>{mode=="country_name"? data?.country_name:data?.dialling_code}</option> 
                            
                        </>
                    );
                })}
            </select>
        </div>
    );
};

export default Country;
