import { useState, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { AiFillFire } from "react-icons/ai";
import Navbar from "../Navbar";
import { Link, useParams } from "react-router-dom";
import { getAllMenu } from "../../store/api/listingmenu";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { MenuProduct } from "../../store/Type/menu.type";
import { PRODCUCTIMAGE_BASE_URL } from "../../constant";
import Footer from "../Footer";
import { Drawer } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import text_truncate from "../TextTruncate";
import { LoadingOutlined } from "@ant-design/icons";
import { getAllTags } from "../../store/api/tag";
import { BsChevronDown } from "react-icons/bs";
import { getAllCategories } from "../../store/api/category";
import AppButton from "../AppButton";
import Loader from "../Loader";

const Index = (props: any) => {
    const dispatch = useDispatch<any>();
    const { id, currentVenue, restaurantid } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [filtervisible, setFilterVisible] = useState(false);
    const [menuvisible, setMenuVisible] = useState(false);
    const [tag, setTag] = useState<number[]>([]);
    const [selectedTag, setSelectedTag] = useState<number[]>([]);
    const [menuCategory, setMenuCategory] = useState<number[]>([]);

    const [filterPlacement, setFilterPlacement] =
        useState<DrawerProps["placement"]>("left");
    const [menuPlacement, setMenuPlacement] =
        useState<DrawerProps["placement"]>("right");

    useEffect(() => {
        const data: any = { id, tag: String(tag) };
        id && dispatch(getAllMenu(data));
        restaurantid && dispatch(getAllTags(restaurantid));
        dispatch(getAllCategories(currentVenue));
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, [tag]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    const { totalQty, loader } = useSelector((state: StateType) => state.cart);
    const curr = useSelector(
        (state: StateType) => state.category.categoryRes.currency
    );
    const menuDetails = useSelector((state: StateType) => state.menu.menu);
    const dietTag = useSelector((state: StateType) => state.tags.tags);
    const { categoryRes } = useSelector((state: StateType) => state.category);
    const [openSearch, setOpenSearch] = useState(false);
    const openSearchHandler = (e: any) => {
        e.preventDefault();
        setOpenSearch(!openSearch);
    };
    const onCancel = (e: any) => {
        e.preventDefault();
        setOpenSearch(!openSearch);
    };
    const handleFilter = () => {
        setTag(selectedTag);
        setFilterVisible(false);
    };
    const onClose = () => {
        setFilterVisible(false);
    };
    const menuClose = () => {
        setMenuVisible(false);
    };
    const showFilterDrawer = () => {
        setFilterVisible(true);
    };
    const showMenuDrawer = () => {
        setMenuVisible(true);
    };
    const handleCheckbox = (id: number) => {
        if (tag.includes(id)) {
            const filteredTag = tag.filter((item) => item !== id);
            setSelectedTag(filteredTag);
        } else {
            setSelectedTag([...tag, id]);
        }
    };
    const handleMenuCheck = (id: number) => {
        const dataa: any = { id, menuCategory: String(menuCategory) };
        id && dispatch(getAllMenu(dataa));
        setMenuVisible(false);
        if (!menuCategory.includes(id)) {
            setMenuCategory([...menuCategory, id]);
        }
    };
    return (
        <>
            <Navbar showArrow showLogo venue={currentVenue} />
            {loader ? (
                <Loader />
            ) : (
                <div className="whole_listing">
                    <div className="listing-menu container">
                        <div className="top-bar">
                            <div className="topbar_left">
                                <div className="search-icon">
                                    <BiSearch
                                        className="bisearch"
                                        onClick={openSearchHandler}
                                    />
                                </div>
                                <div
                                    className="filter-icon"
                                    onClick={showFilterDrawer}
                                >
                                    <img src="/Images/filter.png" />
                                </div>
                            </div>
                            <div
                                className="topbar_right"
                                onClick={showMenuDrawer}
                            >
                                View-menu
                                <BsChevronDown className="viewmenu_arrow" />
                            </div>
                        </div>
                        <div
                            className={
                                openSearch ? "search-bar active" : "search-bar"
                            }
                        >
                            <div className="input-search">
                                <input
                                    type="text"
                                    onChange={(event) => {
                                        setSearchTerm(event.target.value);
                                    }}
                                />
                                <BiSearch
                                    className="bisearch_inner-icon"
                                    onClick={openSearchHandler}
                                />
                            </div>
                            <div className="cancel-btn" onClick={onCancel}>
                                Cancel
                            </div>
                        </div>

                        <div className="menu_wrapper">
                            <h2>{menuDetails.category?.name}</h2>
                            <div className="menu_innerbox">
                                {/* {loader && <div className="loading-spinner listing-loader"></div>} */}

                                {menuDetails?.data
                                    ?.filter((val) => {
                                        if (searchTerm == "") {
                                            return val;
                                        } else if (
                                            val.name
                                                .toLowerCase()
                                                .includes(
                                                    searchTerm.toLowerCase()
                                                )
                                        ) {
                                            return val;
                                        }
                                    })
                                    .map((item: MenuProduct, index: number) => {
                                        return (
                                            <>
                                                <Link
                                                    to={
                                                        item.status == 0
                                                            ? ""
                                                            : `/menu/${currentVenue}/detailmenu/${item.id}`
                                                    }
                                                >
                                                    <div
                                                        // className="menu_items"
                                                        className={
                                                           
                                                            item.photo
                                                                ? "menu_itemsactive" 
                                                                : "menu_items"
                                                        }
                                                        key={index}
                                                    >
                                                        {item.sold == 0 && (
                                                            <div
                                                                className={
                                                                    item.photo
                                                                        ? "sold_out"
                                                                        : "soldinactive"
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        item.photo
                                                                            ? "sold_span"
                                                                            : "sold_spancenter"
                                                                    }
                                                                >
                                                                    Sold Out
                                                                </span>
                                                            </div>
                                                        )}
                                                        {item?.photo && (
                                                            <div className="listingproduct_img">
                                                                <img
                                                                    src={`${PRODCUCTIMAGE_BASE_URL}/${item.photo}`}
                                                                    alt="menu"
                                                                />
                                                            </div>
                                                        )}
                                                        <div
                                                            className={
                                                                !item.photo
                                                                    ? "menu-wholecontent"
                                                                    : item.sold ==
                                                                      0
                                                                    ? "menuinnercontent_opacity"
                                                                    : "menu_inner_content"
                                                            }
                                                        >
                                                            <div className="menu_subcontentI">
                                                                {item.is_popular ==
                                                                1 ? (
                                                                    <div className="menu_status">
                                                                        <span>
                                                                            Popular
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {item.is_hot ==
                                                                1 ? (
                                                                    <div className="aifire">
                                                                        <AiFillFire className="aifire-icon" />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <h4>
                                                                    {text_truncate(
                                                                        {
                                                                            str: item.name,
                                                                            length: 20,
                                                                            ending: null,
                                                                        }
                                                                    )}
                                                                </h4>
                                                                <p>
                                                                    {text_truncate(
                                                                        {
                                                                            str: item.description,
                                                                            length: 27,
                                                                            ending: null,
                                                                        }
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="menu_subcontent">
                                                                <div className="price">
                                                                    {curr}
                                                                    &nbsp;
                                                                    {item?.mrp}
                                                                </div>

                                                                <div className="menu__GVD">
                                                                    {item?.tags
                                                                        ?.slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                        .map(
                                                                            (
                                                                                tag,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <span
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            tag
                                                                                        }
                                                                                    </span>
                                                                                );
                                                                            }
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <Footer />
                    <div className="view_orderbtn">
                        <Link to={`/menu/${currentVenue}/order`}>
                            <AppButton btnType="primary">
                          
                                View Order
                                <span
                                    className={
                                        totalQty
                                            ? "viewordr_count_active"
                                            : "viewordr_count"
                                    }
                                >
                                    {totalQty}
                                </span>
                            </AppButton>
                        </Link>
                    </div>
                </div>
            )}
            <Drawer
                title="Dietary options"
                placement={filterPlacement}
                onClose={onClose}
                visible={filtervisible}
                className="listing_drawer"
            >
                <div className="filter__drawer">
                    <div className="filter_content_wrapper">
                        {dietTag?.map((diet: any, index: number) => {
                            return (
                                <>
                                    <label
                                        className="drawer_dietry_content"
                                        key={index}
                                    >
                                        <span>
                                            {text_truncate({
                                                str: diet?.name,
                                                length: 20,
                                                ending: null,
                                            })}
                                        </span>
                                        <input
                                            type="checkbox"
                                            onChange={() => {
                                                handleCheckbox(diet.id);
                                            }}
                                        />
                                    </label>
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className="filter_applybtn" onClick={handleFilter}>
                    <button>Apply</button>
                </div>
                {/* <div className="listing_apply_btn" onClick={handleFilter}>
                    <button>APPLY</button>
                </div> */}
            </Drawer>

            <Drawer
                title="Drawer with extra actions"
                placement={menuPlacement}
                onClose={menuClose}
                visible={menuvisible}
                className="viewmenu_drawer"
            >
                {categoryRes?.categories?.map((category: any, i: number) => {
                    return (
                        <>
                            <div
                                className="menu_categoryitem"
                                onClick={() => handleMenuCheck(category.id)}
                                key={i}
                            >
                                {text_truncate({
                                    str: category.name,
                                    length: 22,
                                    ending: null,
                                })}
                            </div>
                        </>
                    );
                })}
            </Drawer>
        </>
    );
};

export default Index;
