import React from "react";

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="powered_by">
                    <p>Powered by</p>
                    <img src="/Images/logoII.png " alt="logo " />
                </div>
                <p className="welcomepage__desc">
                    Beautiful menus for QR code table ordering, pickup delivery
                    and payments.
                </p>
                <a href="/" className="learnmore">Learn More</a>
            </div>
        </>
    );
};

export default Footer;
