import React from "react";

type LandingCardProps = {
    heading?: string;
    image?: string;
    coheading?: string;
    paragraph?: string;
    cardType?: "normal" | "reverse";
    contentList?: Array<contentListProps>;
};
type contentListProps = {
    contentheading: string;
    contentpara: string;
};
const LandingCard = (props: LandingCardProps) => {
    const { heading, image, cardType, contentList } = props;
    return (
        <div className={`landingCard landingCard-${cardType}`}>
            <div className="landing-cardimg">
                <img src={image} alt="landing-image" />
            </div>
            <div className="landing-cardcontent">
                <h2 className="landing_headingII">{heading}</h2>
                {contentList?.map((data, index) => {
                    return (
                        <div className="landingcard_content-2" key={index}>
                            <div className="card_checkbox">
                                <img
                                    src="Images/tick.svg"
                                    alt="checbox-icon"
                                />
                            </div>
                            <div className="l-inner-content">
                                <h3 className="landingcard-coheading">
                                    {data.contentheading}
                                </h3>
                                <p className="landingcard_paragraph">
                                    {data.contentpara}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default LandingCard;
