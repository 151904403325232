import React from "react";
import { Link } from "react-router-dom";
import AppButton from "../AppButton";
import Country from "./Country";

const AboutUser = () => {
    return (
        <div className="signup_wrapper">
            <div className="signup_container">
                <h3 className="signup_heading">
                    First, tell us about yourself
                </h3>
                <p className="about-subheading">
                    Welcome to ORDR! To set up your account, please complete the
                    required field
                </p>
                <form className="signup_form">
                    <div className="signup_inputfield">
                        <input type="email" placeholder="First Name" />
                    </div>
                    <div className="signup_inputfield">
                        <input type="email" placeholder="Last Name" />
                    </div>
                   
                    <div className="signup_inputfield">
                        <Country mode="country_name"/>
                    </div>
                    <div className="signup_inputfield ">
                       <div className="signup_smselect"> <Country /></div>
                        <input type="number" placeholder="PhoneNumber" className="related_inputfield"/>
                    </div>
                    <Link to="/about-company">
                    <AppButton btnType="lgbutton">Create</AppButton>
                        </Link>
                </form>
            </div>
        </div>
    );
};

export default AboutUser;
