import React from 'react'
import AppButton from '../AppButton'
import Country from './Country'

const AboutCompany = () => {
  return (
    <>
      <div className="signup_wrapper">
            <div className="signup_container">
                <h3 className="signup_heading">
                Tell us about your company
                </h3>
                <p className="about-subheading">
                Please fillup the information required below
                </p>
                <form className="signup_form">
                    <div className="signup_inputfield">
                        <input type="email" placeholder="Venue Name" />
                    </div>
               
                    <div className="signup_inputfield ">
                       <div className="signup_smselect"> <div className='select_input'>https://</div></div>
                        <input type="text" placeholder="Company URL" className="related_inputfield"/>
                    </div>
                    <div className="signup_inputfield ">

                    <select  placeholder="-Select your Country Type-" className="select_input" >
             <option value="" disabled selected hidden>- Select your Business Type -</option>

                        <option>
                            Business Type
                        </option>
                        <option>
                            Resturant
                        </option>
                        <option>
                            Hotel
                        </option>
                        <option>
                            Golf Course
                        </option>
             
            </select>
                   </div>
                    <AppButton btnType="lgbutton">Continue</AppButton>
                    <AppButton btnType="black-outlined">Back</AppButton>
                </form>
            </div>
        </div>
    </>
  )
}

export default AboutCompany
