import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "antd/dist/antd.css";

import ResturantProfile from "./components/ResturantProfile";
import "../src/styles/main.scss";
import Verification from "./pages/Verification/index";
import OtpConfirmation from "./pages/OtpConfirmation";
import Category from "./components/Category";
import Payment from "./components/Payment";
import Empty from "./components/Empty";
import Greeting from "./pages/Greeting";
import ListingMenu from "./components/ListingMenu";
import DetailMenu from "./components/DetailMenu";
import { Provider } from "react-redux";
import store from "./store/store";
import Order from "./pages/Order";
import Feedback from "./components/Feedback/Feedback";
import LandingPage from "./pages/LandingPage";
import Receipt from "./components/Receipt/receipt";
import Signup from "./components/Signup/SignUp";
import Verify from "./components/Signup/Verify";
import AboutUser from "./components/Signup/AboutUser";
import AboutCompany from "./components/Signup/AboutCompany";

import Privacy from "./components/Terms/Privacy";
import Terms from "./components/Terms/Terms";
import TermsPrivacy from "./pages/TermsPrivacy";
import AboutBusiness from "./components/Signup/AboutBusiness";


function App(props: any) {
    AOS.init({
        // duration: 3000,
    });
    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route
                            path="menu/:venue"
                            element={<ResturantProfile />}
                        />
                        <Route
                            path="menu/:currentVenue/receipt"
                            element={<Receipt/>}
                        />
                        <Route
                            path="terms"
                            element={<Terms/>}
                        />
                        <Route
                            path="privacy"
                            element={<Privacy/>}
                        />
                        <Route
                            path="terms-privacy"
                            element={<TermsPrivacy/>}
                        />
                        <Route
                            path="signup"
                            element={<Signup/>}
                        />
                       <Route
                            path="verify"
                            element={<Verify/>}
                        />
                        <Route
                            path="about-business"
                            element={<AboutBusiness/>}
                        />
                        <Route path="about-user" element={<AboutUser />}></Route>
                        <Route path="about-company" element={<AboutCompany />}></Route>

                        <Route
                            path="verification"
                            element={<Verification />}
                        ></Route>
                        <Route
                            path="otp-confirmation"
                            element={<OtpConfirmation />}
                        ></Route>
                        <Route
                            path="menu/:currentVenue/category"
                            element={<Category />}
                        ></Route>
                        <Route
                            path="menu/:currentVenue/order"
                            element={<Order />}
                        ></Route>
                        <Route
                            path="menu/:currentVenue/listingmenu/:id/:restaurantid"
                            element={<ListingMenu />}
                        ></Route>
                        <Route
                            path="menu/:currentVenue/detailmenu/:id"
                            element={<DetailMenu />}
                        ></Route>
                        <Route path="payment" element={<Payment />}></Route>
                        <Route
                            path="menu/:currentVenue/greeting"
                            element={<Greeting />}
                        ></Route>
                        <Route
                            path="menu/:currentVenue/feedback"
                            element={<Feedback />}
                        ></Route>
                        <Route path="empty" element={<Empty />}></Route>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </>
    );
}
export default App;
