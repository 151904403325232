import React from 'react'

const AboutBusiness = () => {
  return (
    <div className='aboutbusiness_container'>
        <div className='aboutbusiness_leftcontaner'>
        <div className="signup_logo">
                    <img src="/Images/logoII.png" alt="logo" />
                </div>
                <h2 className="signup_heading">Tell us about Business</h2>
                <p className="about-subheading">
                Please fillup the information required below
                </p>
                <h5>
                Select one category that best describes your field:
                </h5>
                <div className='aboutbusiness_tabs'>
                  <div>Resturant</div>
                  <div>Hotel</div>
                  <div>Entertainment Venue</div>
                  <div>Sports Venue</div>
                </div>
                <h5>
                Pick your Restaurant category (optional):
                </h5>
                <div className='aboutbusiness_tabs'>
                  <div>Quick Service</div>
                  <div>Hotel</div>
                  <div>Entertainment Venue</div>
                  <div>Sports Venue</div>
                </div>
        </div>
      
    </div>
  )
}

export default AboutBusiness
