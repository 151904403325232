import React from "react";
import AppButton from "../../components/AppButton";
import AppCard from "../../components/Landing/AppCard";
import LandingBanner from "../../components/Landing/LandingBanner";
import LandingCard from "../../components/Landing/LandingCard";
import LandingFooter from "../../components/Landing/LandingFooter";
import LandingMenu from "../../components/Landing/LandingMenu";
import LandingNav from "../../components/Landing/LandingNav";
import LandingPackage from "../../components/Landing/LandingPackage";
import ReadyToStart from "../../components/Landing/ReadyToStart";
import TrustedSection from "../../components/TrustedSection";


const index = () => {
    const CardList = [
        {
            imagescr: "../Images/lcardII.jpg",
            heading:
                "Combat the labor shortage and simplify operations by offering guests a better way to order and pay.",
            ContentList: [
                {
                    contentheading: "Serve more guests with fewer staff",
                    contentpara:
                        "Eliminate wait times and long lines by providing a flexible service model. Servers and guests can start and add to the tab, and items are immediately sent to the kitchen",
                },
                {
                    contentheading: "Update digital menus on the fly",
                    contentpara:
                        "Seamless POS integration means you can update your menus in real time. 86 an item from anywhere or test new pricing without wasting time or money printing new menus.",
                },
                {
                    contentheading: "Save your guests and staff time",
                    contentpara:
                        "Simplify checkout for guests and staff. With Apple Pay, guests can pay in as little as 10 seconds",
                },
            ],
        },
        {
            imagescr: "../Images/lcardIII.jpg",
            heading:
                "Increase revenue and check size while reducing costly comps and voids.",
            ContentList: [
                {
                    contentheading: "Increase revenue",
                    contentpara:
                        "Toast Mobile Order & Pay customers typically see an average 10% increase in revenue³",
                },
                {
                    contentheading: "Increase check size",
                    contentpara:
                        "On average, check size is 9% higher for customers using Toast Mobile Order & Pay™ vs. standard dine in⁴. With item descriptions, filters, and simple menu modifiers, guests upsell themselves",
                },
                {
                    contentheading: "Reduce comps and voids",
                    contentpara:
                        "Guests can conveniently submit their own order from their own device, reducing costly comps and voids",
                },
            ],
        },
        {
            imagescr: "../Images/lcard.jpg",
            heading: "Collect valuable data to keep guests coming back",
            ContentList: [
                {
                    contentheading: "Power your marketing",
                    contentpara:
                        "Collect guest data on each transaction that can be leveraged to power your marketing program, and generate up to $2,800 in sales per campaign with email marketing",
                },
                {
                    contentheading: "Increase loyalty sign ups",
                    contentpara:
                        "Boost repeat visits by prompting every guest to sign up for your loyalty program at checkout",
                },
                {
                    contentheading: "Improve menu profitability",
                    contentpara:
                        "Unlock valuable menu insights with Toast Performance Center. Review a weekly snapshot of items like guest favorites and slow sellers",
                },
            ],
        },
    ];
    const Package = [
        {
            title: "Starter",
            paragraph:
                "Ideal for small restaurants who need one or two terminals to get up and running.",
            subTitle: "$0/month",
            PackageList: [
                {
                    content: "Cloud-based point of sale",
                },
                {
                    content:
                        "Hardware configuration including one terminal. (Additional charges apply for subsequent devices.",
                },
                {
                    content:
                        " Bring your restaurant online with Online Ordering, Toast TakeOut, and Toast Delivery Services.",
                },
                {
                    content:
                        "Guest engagement tools including email marketing, gift cards, and loyalty.",
                },
            ],
        },
        {
            title: "Essentials",
            paragraph:
                "Ideal for small restaurants who need one or two terminals to get up and running.",
            subTitle: "$165/month",
            PackageList: [
                {
                    content: "Cloud-based point of sale",
                },
                {
                    content:
                        "Hardware configuration including one terminal. (Additional charges apply for subsequent devices.",
                },
                {
                    content:
                        " Bring your restaurant online with Online Ordering, Toast TakeOut, and Toast Delivery Services.",
                },
                {
                    content:
                        "Guest engagement tools including email marketing, gift cards, and loyalty.",
                },
            ],
        },
        {
            title: "Custom",
            paragraph:
                "Ideal for small restaurants who need one or two terminals to get up and running.",
            subTitle: "Custom Price",
            PackageList: [
                {
                    content: "Cloud-based point of sale",
                },
                {
                    content:
                        "Hardware configuration including one terminal. (Additional charges apply for subsequent devices.",
                },
                {
                    content:
                        " Bring your restaurant online with Online Ordering, Toast TakeOut, and Toast Delivery Services.",
                },
                {
                    content:
                        "Guest engagement tools including email marketing, gift cards, and loyalty.",
                },
            ],
        },
    ];
    return (
        <div className="landingPage_wrapper">
            <LandingNav />
            <LandingBanner />
            <div className="container_wrap">
            <TrustedSection />
            <AppCard />
            <div className="landingcard_wrapper padding">
                {CardList?.map((carddata, index) => {
                    return (
                        <LandingCard
                            cardType={index % 2 == 0 ? "normal" : "reverse"}
                            image={carddata.imagescr}
                            heading="Combat the labor shortage and simplify operations by offering guests a better way to order and pay."
                            contentList={carddata.ContentList}
                            key={index}
                        />
                    );
                })}
            </div>
            <LandingMenu />
            <div className="landingPackage_section">
                <h2 className="landing_headingII">
                    The right package for every restaurant
                </h2>
                <div className="landingpackage_wrapper">
                    {Package?.map((packageData, index) => {
                        return (
                            <LandingPackage
                                key={index}
                                title={packageData.title}
                                paragraph={packageData.paragraph}
                                subTitle={packageData.subTitle}
                                PackageList={packageData.PackageList}
                            />
                        );
                    })}
                </div>
            </div>
            </div>
            <ReadyToStart />
          
            <LandingFooter />
            {/* <!-- Start of HubSpot Embed Code --> */}
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/22361804.js"></script>
            {/* <!-- End of HubSpot Embed Code --> */}
        </div>
    );
};

export default index;
