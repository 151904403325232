import React from "react";
import { FaHeart } from "react-icons/fa";
import { IoIosPeople, IoMdPerson } from "react-icons/io";
import ReadMore from "../ReadMore";

type CardProps = {
    children?: any;
};
const AppCard = (props: CardProps) => {
    const TopCard = [
        {
            icon: <img src="../Images/cardiconIII.svg" />,
            cardHeading: "Combat the labor shortage",
            cardParagraph:
                "Passionate about the restaurant industry? Us, too. View open roles or join our talent community.",
            label: "Learn More",
            duration:2000
        },
        {
            icon: <img src="../Images/cardiconII.svg" />,
            cardHeading: "Increase Revenue & Check Size",
            cardParagraph:
                "The philanthropic arm of Toast, Toast.org was founded to make the community we serve even better.",
            label: "Learn More",
            duration:4000

        },
        {
            icon: <img src="../Images/cardiconI.svg" />,
            cardHeading: "Collect Valuable Guest Data",
            cardParagraph:
                "Attend an industry event, refer a restaurant to Toast, or meet other restaurateurs in your area.",
            label: "Learn More",
            duration:6000

        },
    ];
    const { children } = props;
    return (
        <>
        <div className="main_card">
            <div className="l-topcardwrapper container_wrap">
                <img
                    src="/Images/lFoodIII.png"
                    className="imageicon imageiconIII"
                    alt="bread-img"
                />
                <img
                    src="/Images/lFoodIV.png"
                    className="imageicon imageiconIV"
                    alt="bread-img"
                />
                <div className="lcard_content">
                    <h2 className="landing_headingII">
                        Why 0ver 57,000 Restaurants Love Toast
                    </h2>
                    <p className="landing_paragraph">
                        Grow your business online with your own commission-free
                        ordering channels for takeout and delivery- whether
                        that’s on your website, the Toast
                    </p>
                </div>
                <div className="lcard_box">
                    {/* <div className="l-topcardwrapper"> */}
                    {TopCard.map((data, index) => {
                        return (
                            <>
                                <div
                                    className="landingtopcard appCard"
                                    data-aos="flip-right"
                                    // data-aos-delay="100"
                                    data-aos-duration="3000"
                                    
                                    key={index}
                                >
                                    <div className="card_icon">{data.icon}</div>
                                    <h3 className="heading-III">
                                        {data.cardHeading}
                                    </h3>
                                    <p>{data.cardParagraph}</p>
                                    <ReadMore label={data.label} />
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
            </div>
        </>
    );
};
export default AppCard;
