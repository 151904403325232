import React from 'react'

const Loader = () => {
  return (
    <div className="loading-spinner"></div>
    // <div className="loading-spinner listing-loader"></div>

  )
}

export default Loader
