import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, Input, InputNumber } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";

interface Props {
    visible?: boolean;
    onClose: () => void;
    onChangeOne: (e: any) => void;
    onChangeTwo: (e: any) => void;
    onSubmit: () => void;
    buttondisabled?: boolean;
    validationMsgOne?: string;
    validationMsgTwo?: any;
}

const Index = ({
    visible,
    onClose,
    onChangeOne,
    onChangeTwo,
    onSubmit,
    buttondisabled,
    validationMsgOne,
    validationMsgTwo,


}: Props) => {
    const homeData = useSelector((state: StateType) => state.profile.profile);
    const makeOption = () => {
        const options =
            homeData?.profile?.table?.map((data: any, index: any) => {
                return (
                    <option key={index} value={data.key}>
                        {data.key}
                    </option>
                );
            }) || [];
        return options;
    };
    const [placement, setPlacement] =
        useState<DrawerProps["placement"]>("bottom");
    const onFinish = (values: any) => {};

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const on_Submit = () => {
        onSubmit();
        onClose();
    };

    return (
        <div>
            <Drawer
                title={
                    homeData?.profile?.type == 2
                        ? "Please enter your Room number"
                        : homeData?.profile?.type == 3
                        ? "Please enter your Tee number"
                        : "Where are you seated?"
                }
                placement={placement}
                width={500}
                onClose={onClose}
                visible={visible}
                className="table__drawer"
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="table__form"
                >
                    <Form.Item
                        label={
                            homeData?.profile?.type == 2
                                ? "Room Number"
                                : homeData?.profile?.type == 3
                                ? "Tee Number"
                                : "Table Number"
                        }
                        name="tablenum"
                        rules={[
                            {
                                required: true,
                                message: "Table number is required",
                            },
                        ]}
                    >
                        <Input
                            placeholder={
                                homeData?.profile?.type == 2
                                    ? "Room number"
                                    : homeData?.profile?.type == 3
                                    ? "Tee number"
                                    : "Table Number"
                            }
                            onChange={onChangeOne}
                            
                        />
                        {validationMsgOne && (
                            <p className="drawer_validationmsg">
                                {validationMsgOne}
                            </p>
                        )}
                        
                    </Form.Item>
                    {homeData?.profile?.type == 3
                                 &&
                    <Form.Item
                        label="Name"
                        name="name"
                        className={homeData?.profile?.type==3? "name_inputfield  active":"name_inputfield"}
                        rules={[
                            {
                                required: true,
                                message: "Please provide last name on your booking",
                            },
                        ]}
                    >
                        <Input
                            placeholder="Last name on booking"
                            onChange={onChangeTwo}
                        
                        />
                       {validationMsgTwo && (
                            <p className="drawer_validationmsg">
                                {validationMsgTwo}
                            </p>
                        )}
                    </Form.Item>
                    }
                </Form>
                <Form.Item>
                    <Button
                        onClick={on_Submit}
                        htmlType="submit"
                        disabled={buttondisabled}
                        className={buttondisabled ? "disabledBtn" : ""}
                    >
                        Continue
                    </Button>
                </Form.Item>
            </Drawer>
        </div>
    );
};

export default Index;
