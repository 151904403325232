import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { Drawer, Button, Space, Radio } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import type { RadioChangeEvent } from "antd/es/radio";
import { IoMdClose } from "react-icons/io";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from "../../store/api/resturantProfile";
import { getAllCategories } from "../../store/api/category";
import { HomeIMAGE_BASE_URL } from "../../constant";
import { StateType } from "../../store/Reducer/index.reducer";

interface Props {
    showNavMenu?: boolean;
    showArrow?: boolean;
    showDineIn?: boolean;
    showLogo?: boolean;
    venue?: string;
}

const Navbar: React.FC<Props> = (props) => {
    const dispatch: any = useDispatch();
    const { venue } = useParams();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [placement, setPlacement] =
        useState<DrawerProps["placement"]>("left");
        const homeData = useSelector((state: StateType) => state.profile.profile);

    const goBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        if (props.venue != undefined) {
            dispatch(getProfileApi(props.venue));
            dispatch(getAllCategories(currentVenue));
        }
    }, [props.venue]);
    const showDrawer = () => {
        setVisible(true);
        setDrawerOpen(true);
    };
    const onChange = (e: RadioChangeEvent) => {
        setPlacement(e.target.value);
    };
    const { id, currentVenue } = useParams();
    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <div className="navbar">
                {props.showNavMenu && (
                    <div className="navmenu">
                        <Space>
                            <HiMenuAlt1 fontSize={24} onClick={showDrawer} />
                        </Space>

                        <Drawer
                            placement={placement}
                            width={500}
                            onClose={onClose}
                            visible={visible}
                            className="menu__sidebar"
                        >
                            <div className="close-icon">
                                <IoMdClose onClick={onClose} />
                            </div>
                            <div className="drawer__menucontent">
                                <div className="menu-sidebar__img">
                                    <img src={
                                homeData?.profile?.logo
                                    ? `${HomeIMAGE_BASE_URL}/${homeData?.profile?.logo}`
                                    : "/Images/logoII.png"
                            } alt="logo"/>

                                </div>

                                <ul>
                                    {/* <li>Start a tab</li> */}
                                    <li>
                                        <Link
                                            className="side_link"
                                            to={`/menu/${props.venue}/feedback`}
                                        >
                                            Leave a feedback
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            className="side_link"
                                            href="/"
                                        >
                                            Learn more about ORDR.
                                        </a>
                                    </li>
                                    {/* <Link className="nav-link" to="/order">
                                        <li>View orders & receipts</li>
                                    </Link>

                                    <Link
                                        className="nav-link"
                                        to="/otp-confirmation"
                                    >
                                        <li> Log in</li>
                                    </Link> */}
                                </ul>
                            </div>
                            <div className="sidebar__content">
                                <p className="content-II">
                                    {homeData?.profile?.address}, 
                                    {homeData?.profile?.country}
                                </p>
                                <p className="content-II">{homeData?.profile?.phone}</p>
                                <p className="content-II">{homeData?.profile?.email}</p>
                                <div className="menu__code">
                                    <span>EN</span>
                                    <span>DE</span>
                                    <span>FR</span>
                                    <span>EL</span>
                                    <span>CN</span>
                                    <span>ES</span>
                                    <span>IT</span>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                )}
                {props.showArrow && (
                    <div className="left-arrow">
                        <BsArrowLeft
                            fontSize={16}
                            className="left-arrow__icon"
                            onClick={goBack}
                        />
                    </div>
                )}

                {props.showLogo && (
                        <div className="nav-venuename">
                    <Link to={props.venue ? `/menu/${props.venue}` : ""}>
                            {homeData?.profile?.name}
                            {/* <img src="/Images/ordrlogo.png"></img> */}
                    </Link>
                        </div>
                )}
                {props.showDineIn && <div className="dinein">Dine-in</div>}
            </div>
        </>
    );
};

export default Navbar;
