import {
    ADD_TO_CART,
    EMPTY_CART,
    GET_CART,
    loaderAction,
    REMOVE_FROM_CART,
} from "../const";
import { CartType } from "../Type/cart.type";

export interface CartReducar {
    product: Array<CartType>;
    subTotal: number;
    venueCharge: number;
    tax:number;
    total: number;
    totalQty: number;
    session_id:string;
    loader: boolean;
}
const initialData: CartReducar = {
    product: [],
    subTotal: 0,
    venueCharge: 0,
    tax:0,
    total: 0,
    totalQty: 0,
    session_id:"",
    loader: false,
};
const cartReducer = (state = initialData, action: any) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                product: action.payload.cart,
                total: action.payload.total,
                subTotal: action.payload.subTotal,
                venueCharge: action.payload.venueCharge,
                tax:action.payload.tax,
                session_id:action.payload.session_id,
                totalQty: action.payload.cart.reduce(
                    (prev: number, current: CartType) =>
                        prev + +current.quantity,
                    0
                ),
                loader: false,
            };
        case GET_CART:
            return {
                ...state,
                product: action.payload.cart,
                total: action.payload.total,
                subTotal: action.payload.subTotal,
                venueCharge: action.payload.venueCharge,
                tax:action.payload.tax,
                session_id:action.payload.session_id,

                totalQty: action.payload.cart.reduce(
                    (prev: number, current: CartType) =>
                        prev + +current.quantity,
                    0
                ),
                loader: false,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                product: action.payload.cart,

                total: action.payload.total,
                subTotal: action.payload.subTotal,
                venueCharge: action.payload.venueCharge,
                tax:action.payload.tax,
                session_id:action.payload.session_id,
                totalQty: action.payload.cart.reduce(
                    (prev: number, current: CartType) =>
                        prev + +current.quantity,
                    0
                ),
                loader: false,
            };
            case EMPTY_CART:
                return{
                    ...state,
                    product: [],
                    subTotal: 0,
                    venueCharge: 0,
                tax:0,

                    total: 0,
                    totalQty: 0,
                }

        default:
            return state;
    }
};
export default cartReducer;
